<div class="container">
  <app-in-line></app-in-line>
  <app-portada></app-portada>
  <!-- <app-tarjeta></app-tarjeta>  -->
  <app-why-bg></app-why-bg>
  <!-- <app-compranos></app-compranos> -->
  <app-central></app-central>

  <div style="background-color: #ff7d01; border-radius: 20px;">

    <app-redes style="--icon-color: #ffffff; --hover-color: #fd2316;"></app-redes>
  </div>

  <app-hablanos></app-hablanos>
</div>