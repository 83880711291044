// 1. Módulos Angular Core
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// 2. Otros Módulos de Angular
// (Tu ejemplo no tiene, pero aquí iría, por ejemplo, HttpClientModule si lo necesitaras)

// 3. Módulos de Terceros
// Importaciones de módulos de terceros aquí
// (Si hubiera alguno adicional a FlexLayoutModule y MaterialModule, irían aquí)

// 4. Módulos Personalizados
import { MaterialModule } from '../material/material.module';
// import { HttpClientModule } from '@angular/common/http'; // Suponiendo que es un módulo personalizado o de terceros, pero en realidad es de Angular

// 5. Componentes, Directivas, Pipes, etc.
import { AniversarioComponent } from './aniversario/aniversario.component';
import { CaruselUnoComponent } from './carusel-uno/carusel-uno.component';
import { CompranosComponent } from './compranos/compranos.component';
import { ConceptoComponent } from './concepto/concepto.component';
import { ContactoPieComponent } from './contacto-pie/contacto-pie.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { EventoportadaComponent } from './eventoportada/eventoportada.component';
import { FooterComponent } from './footer/footer.component';
import { HablanosComponent } from './hablanos/hablanos.component';
import { HeaderComponent } from './header/header.component';
import { HistoriaComponent } from './historia/historia.component';
import { InLineComponent } from './in-line/in-line.component';
import { MenuComponent } from './menu/menu.component';
import { PiecardComponent } from './piecard/piecard.component';
import { PortadaComponent } from './portada/portada.component';
import { RedesComponent } from './redes/redes.component';
import { TarjetaComponent } from './tarjeta/tarjeta.component';
import { TiktokComponent } from './tiktok/tiktok.component';
import { WhyBgComponent } from './why-bg/why-bg.component';
import { WpButtonComponent } from './wp-button/wp-button.component';
import { NavSidebarComponent } from './nav-sidebar/nav-sidebar.component';
import { CentralComponent } from './central/central.component';
import { FotosHistoriaComponent } from './fotos-historia/fotos-historia.component';
import { MisionComponent } from './mision/mision.component';
import { VisionComponent } from './vision/vision.component';
import { ValoresComponent } from './valores/valores.component';
import { TituloProductosComponent } from './titulo-productos/titulo-productos.component';
import { CarruselProductosComponent } from './carrusel-productos/carrusel-productos.component';
import { VolverComponent } from './volver/volver.component';




@NgModule({
  declarations: [
    AniversarioComponent,
    CaruselUnoComponent,
    CompranosComponent,
    ConceptoComponent,
    ContactoPieComponent,
    ErrorPageComponent,
    EventoportadaComponent,
    FooterComponent,
    HablanosComponent,
    HeaderComponent,
    HistoriaComponent,
    InLineComponent,
    MenuComponent,
    PiecardComponent,
    PortadaComponent,
    RedesComponent,
    TarjetaComponent,
    TiktokComponent,
    WhyBgComponent,
    WpButtonComponent,
    NavSidebarComponent,
    CentralComponent,
    FotosHistoriaComponent,
    MisionComponent,
    VisionComponent,
    ValoresComponent,
    TituloProductosComponent,
    CarruselProductosComponent,
    VolverComponent,
  ],
  exports:[
    AniversarioComponent,
    CaruselUnoComponent,
    CompranosComponent,
    ConceptoComponent,
    ContactoPieComponent,
    ErrorPageComponent,
    EventoportadaComponent,
    FooterComponent,
    HablanosComponent,
    HeaderComponent,
    HistoriaComponent,
    InLineComponent,
    MenuComponent,
    PiecardComponent,
    PortadaComponent,
    RedesComponent,
    TarjetaComponent,
    TiktokComponent,
    WhyBgComponent,
    WpButtonComponent,
    NavSidebarComponent,
    CentralComponent,
    FotosHistoriaComponent,
    MisionComponent,
    VisionComponent,
    ValoresComponent,
    VolverComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule
  ]
})
export class ShareModule { }
