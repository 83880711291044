<div class="container-fluid text-center why-bg">
  <h2 class="display-4 py-4 why-title">¿Por qué Bon Gusto?</h2>
  <div class="carousel-container">
    <button class="carousel-control-prev" (click)="scrollLeft()">
      <span class="carousel-control-prev-icon">&#8249;</span>
    </button>

    <div class="carousel-scroll" #carouselScroll>
      <div class="carousel-iitem" *ngFor="let feature of features">
        <div class="quality-inner">
          <div class="feature-icon">
            <i class="{{ feature.icon }} fa-3x"></i>
          </div>
          <p class="h4 fw-bold mt-3">{{ feature.title }}</p>
          <p>{{ feature.description }}</p>

        </div>
      </div>
    </div>

    <button class="carousel-control-next" (click)="scrollRight()">
      <span class="carousel-control-next-icon">&#8250;</span>
    </button>
  </div>
</div>
