import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { InfoContacto } from 'src/app/intefaces/info-contacto.interface';
import { InfoContactoService } from 'src/app/services/info-contacto.service';

@Component({
  selector: 'app-hablanos',
  templateUrl: './hablanos.component.html',
  styleUrls: ['./hablanos.component.css']
})
export class HablanosComponent implements OnInit {

  contactos: InfoContacto[] = [];
  @ViewChild('carouselScroll', { static: true }) carouselScroll!: ElementRef;

  initialX: number = 0;
  movedX: number = 0;

  constructor(public contatosServices: InfoContactoService) { }

  ngOnInit(): void {
    this.contatosServices.getContactos().subscribe(contactos => this.contactos = contactos);
  }

  scrollLeft() {
    this.carouselScroll.nativeElement.scrollBy({ left: -220, behavior: 'smooth' });
  }

  scrollRight() {
    this.carouselScroll.nativeElement.scrollBy({ left: 220, behavior: 'smooth' });
  }

  // Método para iniciar el deslizamiento táctil
  onTouchStart(event: TouchEvent) {
    this.initialX = event.touches[0].clientX;
  }

  // Método para detectar el movimiento táctil
  onTouchMove(event: TouchEvent) {
    this.movedX = event.touches[0].clientX;
    const movement = this.initialX - this.movedX;
    this.carouselScroll.nativeElement.scrollLeft += movement;
  }

  // Método para finalizar el deslizamiento táctil
  onTouchEnd() {
    this.initialX = 0;
    this.movedX = 0;
  }
}
