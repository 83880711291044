import { Component, OnInit } from '@angular/core';
import { puntosService } from 'src/app/mapas/services/puntos.service';
import { Productopdv, ProductosAgrupados } from 'src/app/mapas/interfaces/productospdv.interface';

@Component({
  selector: 'app-catalogo-productos',
  templateUrl: './catalogo-productos.component.html',
  styleUrls: ['./catalogo-productos.component.css']
})
export class CatalogoProductosComponent implements OnInit {
  productosAgrupados: ProductosAgrupados = {};
  paginatedProducts: { [grupo: string]: Productopdv[] } = {};
  pageIndex: number = 0;
  pageSize: number = 10;
  totalProducts: number = 0;

  constructor(private puntosService: puntosService) {}

  ngOnInit() {
    this.puntosService.productospdv().subscribe(productos => {
      console.log(productos); // Verifica los datos aquí
      // Filtrar productos con vigencia = true
      const productosVigentes = productos.filter(producto => producto.vigencia === true);
      this.productosAgrupados = this.agruparProductos(productosVigentes);
      this.totalProducts = productosVigentes.length;
      this.paginarProductos();
    }, error => {
      console.log(error);
    });
  }

  private agruparProductos(productos: Productopdv[]): ProductosAgrupados {
    return productos.reduce((acc: ProductosAgrupados, producto) => {
      const grupo = producto.grupo_mayor;
      if (!acc[grupo]) {
        acc[grupo] = [];
      }
      acc[grupo].push(producto);
      return acc;
    }, {});
  }

  paginarProductos() {
    const startIndex = this.pageIndex * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedProducts = Object.keys(this.productosAgrupados).reduce((acc, key) => {
      acc[key] = this.productosAgrupados[key].slice(startIndex, endIndex);
      return acc;
    }, {} as { [grupo: string]: Productopdv[] });
  }

  onPageChange(event: any) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.paginarProductos();
  }
}
