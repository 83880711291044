<div class="nav-container position-relative shadow-sm">
  <!-- Logo fuera y encima de la barra de navegación -->
  <a class="navbar-brand floating-logo" href="#">
    <img src="assets/imagenes/logobg.svg" alt="Logo">
  </a>
  <nav [class.navbar-pdvsur]="isPdvSurRoute()" class="navbar navbar-expand-lg">
    <button class="navbar-toggler" type="button" (click)="toggleMenu()">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" [class.show]="!isCollapsed">
      <ul class="navbar-nav">
        <li class="nav-item" *ngFor="let menu of menus">
          <a class="nav-link" [routerLink]="menu.link" (click)="isCollapsed || toggleMenu()">{{ menu.name }}</a>
        </li>
      </ul>
    </div>
  </nav>
</div>
