<!-- <div class="hero-image-contacto">
    <div class="container contacto">
         <h2><span>un gusto diferente</span></h2> 
    </div>
</div> -->
<div class="container">
    <div class="row">
        <div class="col p-0">
            <img src="https://firebasestorage.googleapis.com/v0/b/angular-html-517d8.appspot.com/o/portadas%2Fcontacto-phone.webp?alt=media&token=2336622f-4617-4465-8d21-b318bad1ae08"
                class="img-fluid rounded" alt="...">
        </div>
    </div>
    <app-central></app-central>
    
    
    <app-hablanos></app-hablanos>
</div>