<div class="inicio min-vh-50 py-5 bg-transparent">
  <div class="container">
    <h3 class="text-center mb-4 fw-bold text-secondary">Bon Gusto: disfruta de la calidad.</h3>
    <div class="row justify-content-center g-4">
      <ng-container *ngFor="let benefit of benefits">
        <!-- Bootstrap clases para la responsividad -->
        <div class="col-6 col-md-4 col-lg-3">
          <div class="benefit-card">
            <img class="img-fluid" [src]="benefit.image" alt="{{ benefit.title }}">
            <p>{{ benefit.title }}</p>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
