// contacto-pie.component.ts

import { Component, OnInit } from '@angular/core';
import { InfoContactoFooter } from 'src/app/intefaces/info-contactofooter.interface';
import { InfoContactoService } from 'src/app/services/info-contacto.service';

@Component({
  selector: 'app-contacto-pie',
  templateUrl: './contacto-pie.component.html',
  styleUrls: ['./contacto-pie.component.css']
})
export class ContactoPieComponent implements OnInit {
  anio: number = new Date().getFullYear();
  contactos: InfoContactoFooter[] = [];

  constructor(public infoContactoService: InfoContactoService) { }

  ngOnInit(): void {
    this.infoContactoService.getFooter().subscribe({
      next: (data) => {
        this.contactos = data;
      },
      error: (error) => {
        console.error('Error al cargar contactos', error);
      }
    });
  }
}
