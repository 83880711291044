import { Component } from '@angular/core';

@Component({
  selector: 'app-direccionsur',
  templateUrl: './direccionsur.component.html',
  styleUrls: ['./direccionsur.component.css']
})
export class DireccionsurComponent {

}
