<p class="text-center fs-3 text-u mx-auto m-5">
    Videos Destacados
</p>
<div class="video-carousel-container">
    <!-- Botón para retroceder -->
    <button class="carousel-control-prev" (click)="scrollLeft()">
      <span class="carousel-control-prev-icon">&#8249;</span>
    </button>
    <!-- Contenedor del carrusel -->
    <div class="video-carousel-scroll" #videoCarouselScroll>
      <div class="video-carousel-slide" *ngFor="let video of videos">
        <div class="tarjeta-video bg-light shadow pb-1">
          <div class="ratio ratio-16x9">
            <iframe [src]="'https://www.youtube.com/embed/' + video.enlace | youtube"
                    class="video-embed" title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
          </div>
          <div class="card-body">
            <h3 class="titulo-video m-1 fw-bolder">{{video.titulo}}</h3>
            <p class="descripcion-video">{{video.descripcion}}</p>
          </div>
        </div>
      </div>
    </div>
  
    <!-- Botón para avanzar -->
    <button class="carousel-control-next" (click)="scrollRight()">
      <span class="carousel-control-next-icon">&#8250;</span>
    </button>
  </div>
  