import { Component, OnInit } from '@angular/core';
import { InfoProductosService } from 'src/app/services/info-productos.service';

@Component({
  selector: 'app-tarjeta',
  templateUrl: './tarjeta.component.html',
  styleUrls: ['./tarjeta.component.css']
})
export class TarjetaComponent implements OnInit {
  mortadelaItems: any[] = []; // Inicializar como arrays vacíos
  salchichaItems: any[] = []; // Inicializar como arrays vacíos
  otrosProductos: any[] = []; // Inicializar como arrays vacíos

  constructor(private infoProductosService: InfoProductosService) {}

  ngOnInit(): void {
    this.infoProductosService.getTarjetasProductos().subscribe(
      (data: any) => { // Asegurarse de que 'data' es del tipo esperado, podría ser mejor crear una interfaz adecuada
        this.mortadelaItems = data.mortadela;
        this.salchichaItems = data.salchicha;
        this.otrosProductos = data.otrosProductos;
      },
      (error) => {
        console.error('Error al obtener datos de tarjetas de productos', error);
      }
    );
  }
}
