import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { InfoProductosService } from 'src/app/services/info-productos.service';

@Component({
  selector: 'app-carrusel-productos',
  templateUrl: './carrusel-productos.component.html',
  styleUrls: ['./carrusel-productos.component.css']
})
export class CarruselProductosComponent implements OnInit {
  imagenes: any[] = [];
  @ViewChild('carouselScroll', { static: true }) carouselScroll!: ElementRef;

  constructor(private infoProductosService: InfoProductosService, private router: Router) {}

  ngOnInit(): void {
    this.getProductosPortada();
  }

  getProductosPortada() {
    this.infoProductosService.getProductosPortada().subscribe(
      (data) => {
        if (Array.isArray(data)) {
          this.imagenes = data;
        } else if (typeof data === 'object') {
          this.imagenes = Object.values(data);
        } else {
          console.error('Formato de datos no válido. Se esperaba un array u objeto.');
        }
      },
      (error) => {
        console.error('Error al obtener las imágenes de la portada', error);
      }
    );
  }

  navigateTo(enlace: string) {
    this.router.navigate([enlace]);
  }

  scrollLeft() {
    this.carouselScroll.nativeElement.scrollBy({ left: -220, behavior: 'smooth' });
  }

  scrollRight() {
    this.carouselScroll.nativeElement.scrollBy({ left: 220, behavior: 'smooth' });
  }
}
