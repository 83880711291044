<div class="socials-container">
  <div mat-header class="text-center h3 p-2" style="color: white;">Síguenos aquí</div>
  <div class="social-icons">
    <a *ngFor="let red of _servicio.redes"
       [href]="red.enlace"
       target="_blank"
       class="social-link"
       title="{{ red.nombre }}">
      <i class="{{ red.icono }}"></i>
    </a>
  </div>
</div>
