import { Component } from '@angular/core';

@Component({
  selector: 'app-vision',
  templateUrl: './vision.component.html',
  styleUrls: ['./vision.component.css']
})
export class VisionComponent {
  // Variable que contiene el texto de la visión
  visionTexto: string = "Ofrecer el mejor sabor de una amplia gama de productos alimenticios.";
}
