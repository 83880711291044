<div class="carousel-container">
  <!-- Botón para retroceder -->
  <button class="carousel-control-prev" (click)="scrollLeft()">
    <span class="carousel-control-prev-icon" aria-hidden="true">&#8249;</span>
  </button>

  <!-- Contenedor del carrusel -->
  <div class="carousel-scroll" #carouselScroll>
    <div class="carousel-iitem" *ngFor="let imagen of imagenes">
      <img [src]="imagen.ruta" alt="Imagen del producto" class="product-img" />
      <p class="product-name">{{ imagen.nombre }}</p>
      <button mat-button color="warn" class="product-btn" (click)="navigateTo(imagen.enlace)">Ver más</button>
    </div>
  </div>

  <!-- Botón para avanzar -->
  <button class="carousel-control-next" (click)="scrollRight()">
    <span class="carousel-control-next-icon" aria-hidden="true">&#8250;</span>
  </button>
</div>
