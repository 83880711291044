import { Component } from '@angular/core';

@Component({
  selector: 'app-promociones-novedades',
  templateUrl: './promociones-novedades.component.html',
  styleUrls: ['./promociones-novedades.component.css']
})
export class PromocionesNovedadesComponent {

}
