import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eventoportada',
  templateUrl: './eventoportada.component.html',
  styleUrls: ['./eventoportada.component.css']
})
export class EventoportadaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
