<div id="carouselExampleIndicators" class="carousel slide car1 py-3 col-lg-12 col-md-12 col-12" data-bs-ride="carousel">
    <!-- Indicadores generados dinámicamente -->
    <div class="carousel-indicators">
      <button *ngFor="let image of images; let i = index" type="button" data-bs-target="#carouselExampleIndicators" 
              [attr.data-bs-slide-to]="i" [class.active]="i === 0" [attr.aria-label]="'Slide ' + (i + 1)">
      </button>
    </div>
  
    <!-- Contenedor de las imágenes del carrusel -->
    <div class="carousel-inner">
      <div class="carousel-item" *ngFor="let image of images; let i = index" [class.active]="i === 0">
        <img [src]="image" class="d-block w-100" alt="Slide {{i + 1}}">
      </div>
    </div>
  
    <!-- Botones de control para avanzar y retroceder -->
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
  