<!-- tarjeta.component.html -->
<div class="container">
  <!-- Sección de Mortadelas -->
  <div class="row pt-2">
    <div class="col-12">
      <h2 class="text-center m-4">Variedad de mortadelas</h2>
    </div>
    <div class="col-4 col-lg-3" *ngFor="let item of mortadelaItems">
      <a [routerLink]="item.enlace">
        <img [src]="item.image" class="img-fluid rounded mb-3" [alt]="item.alt">
      </a>
    </div>
  </div>

  <!-- Sección de Salchichas -->
  <div class="row pt-2">
    <div class="col-12">
      <h2 class="text-center m-4">Salchicha viena</h2>
    </div>
    <div class="col-4 col-lg-3" *ngFor="let item of salchichaItems">
      <a [routerLink]="item.enlace">
        <img [src]="item.image" class="img-fluid rounded mb-3" [alt]="item.alt">
      </a>
    </div>
  </div>

  <!-- Sección de Otros Productos -->
  <div class="row pt-2">
    <div class="col-12">
      <h2 class="text-center m-4">Variedad de productos</h2>
    </div>
    <div class="col-4 col-lg-3" *ngFor="let item of otrosProductos">
      <a [routerLink]="item.enlace">
        <img [src]="item.image" class="img-fluid rounded mb-3" [alt]="item.alt">
      </a>
    </div>
  </div>
</div>
