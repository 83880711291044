<div class="mapa-embed">
  <iframe
    class="google-map"
    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3824.7091878221445!2d-68.074947!3d-16.5407729!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x915f214ab792e301%3A0x98dedd20d2c59323!2sBon%20Gusto%20punto%20de%20venta%20sur!5e0!3m2!1ses-419!2sbo!4v1711676355659!5m2!1ses-419!2sbo"
    frameborder="0"
    allowfullscreen=""
    loading="lazy"
    referrerpolicy="no-referrer-when-downgrade">
  </iframe>
</div>
