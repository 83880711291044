<mat-list>
    <div mat-header class="text-center central h3 p-2">Nosotros</div>
    
    <!-- Imagen con enlace a la ruta 'inicio' -->
    <a [routerLink]="['/inicio']">
      <img class="marcapie" src="assets/imagenes/logobgrojo.svg" alt="Logo bon gusto">
    </a>
    
    <p class="h5 text-center">Línea <br>embutidos y fiambres</p>
    <p class="h4 text-center">¡Tienes que probarlo!</p>
</mat-list>
