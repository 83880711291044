import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';


interface MenuItem {
  routerLink: string;
  nombre: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('300ms ease-in-out')),
      transition('out => in', animate('300ms ease-in-out'))
    ])
  ]
})
export class HeaderComponent implements OnInit {
  menus: MenuItem[] = [
    {
      routerLink: 'inicio',
      nombre: 'INICIO'
    },
    {
      routerLink: 'productos',
      nombre: 'PRODUCTOS'
    },
    {
      routerLink: 'encuentranos',
      nombre: 'ENCUENTRANOS'
    },
    {
      routerLink: 'contacto',
      nombre: 'CONTACTO'
    },
    {
      routerLink: 'nosotros',
      nombre: 'NOSOTROS'
    },
    {
      routerLink: 'eventos',
      nombre: '20 ANIVERSARIO'
    }
  ];

  activeLink: string = '';
  isCollapsed: boolean = true;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.setActiveLink(this.router.url);
  }

  setActiveLink(routerLink: string) {
    this.activeLink = routerLink;
    this.isCollapsed = true; // Contraer el menú al seleccionar un enlace
  }

  toggleMenu() {
    this.isCollapsed = !this.isCollapsed;
  }
}
