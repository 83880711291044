import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Router } from '@angular/router';

interface MenuItem {
  link: string;
  name: string;
}

@Component({
  selector: 'app-nav-sidebar',
  templateUrl: './nav-sidebar.component.html',
  styleUrls: ['./nav-sidebar.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translateX(0%)'
      })),
      state('out', style({
        transform: 'translateX(-100%)'
      })),
      transition('in => out', animate('300ms ease-in-out')),
      transition('out => in', animate('300ms ease-in-out'))
    ]),
  ],
})


export class NavSidebarComponent implements OnInit {
  isCollapsed = true;
  menus: MenuItem[] = [
    { link: '/inicio', name: 'Inicio' },
    { link: '/productos', name: 'Productos' },
    { link: '/encuentranos', name: 'Encuéntranos' },
    { link: '/contacto', name: 'Contacto' },
    { link: '/nosotros', name: 'Nosotros' },
    { link: '/eventos', name: 'Eventos' },
  ];

  constructor(public router: Router) { }

  ngOnInit(): void {
  }

  toggleMenu(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  isPdvSurRoute() {
    return this.router.url.includes('/encuentranos/pdvsur');
  }
}
