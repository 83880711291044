<div class="contact-card">
  <div class="contact-info">
    <p class="phone-text">
      <i class="fas fa-phone"></i> Llámanos para consultas y pedidos:<br>
      <span class="phone-number m-3">{{ phoneNumber }}</span>
    </p>
    <div class="action-buttons">
      <a href="tel:{{phoneNumber}}" class="action-button call-button" title="Llamar ahora" target="_blank">
        <i class="fas fa-phone-alt"></i> Llamar
      </a>
      <a href="https://wa.me/591{{ phoneNumber }}" class="action-button whatsapp-button" title="Enviar mensaje por WhatsApp" target="_blank">
        <i class="fab fa-whatsapp"></i> WhatsApp
      </a>
    </div>
  </div>
  <p class="help-text">
    ¡Estamos aquí para ayudarte con cualquier duda! Llámanos o envíanos un mensaje para conocer más sobre nuestros productos o hacer tu pedido. ¡Nos encantará atenderte!
  </p>
</div>
