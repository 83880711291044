import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-titulo-productos',
  templateUrl: './titulo-productos.component.html',
  styleUrls: ['./titulo-productos.component.css']
})
export class TituloProductosComponent {
  constructor(private router: Router) {}

  explorarProductos() {
    this.router.navigate(['/productos/linea']);
  }
}
