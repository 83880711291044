<nav class="navbar navbar-expand-lg my-2 p-0 shadow-sm sticky-top">
  <div class="container">
    <a class="navbar-brand objetfit py-0" href="#">
      <img class="navbar-brand d-flex m-0" routerLink="inicio" src="assets/imagenes/logobg.svg" alt="Logo bon gusto" routerLink="inicio">
    </a>
    <button mat-fab class="navbar-toggler" type="button" (click)="toggleMenu()">
      <mat-icon class="text-light" [@slideInOut]="isCollapsed ? 'out' : 'in'">menu</mat-icon>
    </button>
    <div class="collapse navbar-collapse" [class.show]="!isCollapsed">
      <div class="navbar-nav">
        <a *ngFor="let menu of menus" class="fs-7 cool nav-link" [routerLink]="menu.routerLink" routerLinkActive="active" (click)="setActiveLink(menu.routerLink)">{{ menu.nombre }}</a>
      </div>
    </div>
  </div>
</nav>
