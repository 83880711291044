<div class="container-fluid min-vh-25 bg-naranja">
  <section class="row justify-content-center align-items-center py-2">
    <!-- Piecard y redes -->
    <div class="col-lg-3 col-md-5 col-sm-12 mb-3">
      <app-piecard></app-piecard>
    </div>

    <!-- Contacto -->
    <div class="col-lg-3 col-md-5 col-sm-12 mb-3">
      <app-contacto-pie></app-contacto-pie>
    </div>

    <!-- Descarga de logos y redes sociales -->
    <div class="col-lg-3 col-md-5 col-sm-12 mb-3 text-center">
      <app-redes style="--icon-color: #ffffff; --hover-color: #240d09;"></app-redes> <!-- Fondo claro, color icono naranja -->

      <div class="mt-4">
        <span style="color: white;" class="h5">Descarga nuestros logos oficiales</span> <br>
        <a class="btn btn-light btn-sm" target="_blank"
           href="https://drive.google.com/file/d/1tPypVEWCtso2t_HP0UpZsWYuycmCEWM8/view?usp=sharing">
          <strong>Aquí</strong>
        </a>
      </div>
    </div>

    <!-- Derechos de autor -->
    <div class="col-12 text-white text-center py-2">
      <span>&copy; Bon Gusto {{ anio }}. Todos los derechos reservados.</span>
    </div>
  </section>
</div>
