<div class="container historia-container">
  <div class="row align-items-center gx-5">
    <!-- Texto de la historia -->
    <div class="col-md-6">
      <h2 class="pt-4 fs-1">Historia</h2>
      <p>
        Bon gusto nace como una iniciativa familiar fundada por el Sr. Alejandro Quisbert y la Sra. Esperanza Burgoa en
        el año 2003, comenzando con la producción de chorizo parrillero, tocino ahumado y jamón, gracias a los
        conocimientos en embutidos por parte de los fundadores y comercializando estos productos inicialmente en el
        departamento de La Paz y posteriormente en el mercado nacional.
        <br><br>
        La constante superación y búsqueda de un sabor único y diferente es lo que permite el crecimiento de la empresa,
        brindando a sus clientes una gran variedad de productos manteniendo la calidad de una gama de productos
        diversificados.
      </p>
    </div>
    <!-- Componente de fotos -->
    <div class="col-md-6">
      <app-fotos-historia></app-fotos-historia>
    </div>
  </div>
</div>
