<mat-list>
  <div mat-header class="text-center h3 p-2" style="color: white;">Contacto</div>

  <div *ngFor="let contacto of contactos" class="contacto-item ">
    <a [href]="contacto.link" target="_blank" class="contacto-link">
      <i class="{{contacto.icono}} contacto-icono"></i>
      <div class="contacto-texto m-3">
        <h3>{{contacto.tipo}}:</h3>
        <h5>{{contacto.valor}}</h5>
      </div>
    </a>
  </div>
</mat-list>
