import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InfoPagina } from '../intefaces/info-pagina.interface';

@Injectable({
  providedIn: 'root'
})
export class InfoPaginaService {

  info: InfoPagina = {};
  politicas: any = {};
  redes: any = {};

  constructor(private http: HttpClient) {
    this.http.get('https://angular-html-517d8-default-rtdb.firebaseio.com/info.json')
      .subscribe((resp: InfoPagina) => {
        this.info = resp;
      });

    this.http.get('https://angular-html-517d8-default-rtdb.firebaseio.com/politicastabla.json')
      .subscribe((resp: any) => {
        this.politicas = resp;
      });
      this.http.get('https://angular-html-517d8-default-rtdb.firebaseio.com/redesbongusto.json')
      .subscribe((resp: any) => {
        this.redes = resp;
      });
  }
}
