import { Component, OnInit } from '@angular/core';
import { InfoProductosService } from 'src/app/services/info-productos.service';

@Component({
  selector: 'app-in-line',
  templateUrl: './in-line.component.html',
  styleUrls: ['./in-line.component.css']
})
export class InLineComponent implements OnInit {
  images: string[] = []; // Cambié el tipo a string[] para representar las URLs de las imágenes

  constructor(private infoProductosService: InfoProductosService) { }

  ngOnInit(): void {
    // Llama al servicio para obtener los productos en línea
    this.infoProductosService.getProductosInLine().subscribe(
      data => {
        console.log('Datos recibidos del servicio:', data); // Confirmamos los datos que llegan

        // Asegúrate de que 'data' es un array y que cada elemento tiene la propiedad 'image'
        if (Array.isArray(data)) {
          this.images = data.map(item => item.image); // Mapea las imágenes
          console.log('Imágenes para el carrusel:', this.images); // Imprime las imágenes en consola para depuración
        } else {
          console.error('El formato de datos recibido no es un array:', data);
        }
      },
      error => {
        console.error('Error al obtener imágenes para el carrusel:', error);
      }
    );
  }
}
