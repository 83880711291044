import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-piecard',
  templateUrl: './piecard.component.html',
  styleUrls: ['./piecard.component.css']
})
export class PiecardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
