<div class="ancho mb-4">
  <div class="title-container">
    <h1 class="main-title">Conoce nuestra selección de productos</h1>
    <p class="subtitle">Calidad y sabor que puedes disfrutar</p>
  </div>
  <div class="productos">
    <ng-container *ngFor="let grupo of paginatedProducts | keyvalue">
      <h2 class="grupo-title">{{ grupo.key | titlecase }}</h2>
      <div class="tarjetas">
        <mat-card *ngFor="let producto of grupo.value" class="producto-card">
          <mat-card-header>
            <mat-card-title>{{ producto.producto }}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p>Presentación: {{ producto.presentacion }}</p>
            <p>Precio: {{ producto.precio_unitario }} Bs</p>
          </mat-card-content>
        </mat-card>
      </div>
    </ng-container>
  </div>
</div>
