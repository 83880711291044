import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-volver',
  templateUrl: './volver.component.html',
  styleUrls: ['./volver.component.css']
})
export class VolverComponent {

  constructor(private location: Location) {}

  // Método para regresar a la página anterior
  regresar(): void {
    if (window.history.length > 1) {
      // Si el historial es mayor a 1, usa window.history.back()
      this.location.back();
    } else {
      // Si no hay historial, redirige a una página predeterminada, por ejemplo, el inicio
      window.location.href = '/';
    }
  }
}
