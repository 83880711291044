<div class="container my-5">
    <div class="row">

      <div class="col-12 mt-4">
        <div class="news-section">
          <h2 class="news-title text-center">{{_servicio.info.politica}}</h2>
          <div class="news-content">
            <p>
                {{_servicio.info.cuerpo1}}
            </p>
          </div>
        </div>
      </div>

      <div class="col-12 mt-4">
        <div class="news-section">
          <h2 class="news-title text-center">{{_servicio.info.politica2}}</h2>
          <div class="news-content">
            <p>
                {{_servicio.info.cuerpo2}}
            </p>
          </div>
        </div>
      </div>

      <div class="col-12 mt-4">
        <div class="news-section">
          <h2 class="news-title text-center">{{_servicio.info.politica3}}</h2>
          <div class="news-content">
            <p>
              {{_servicio.info.cuerpo3}}
            </p>

            <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Id</th>
                      <th scope="col">Horario</th>
                      <th scope="col">Detalle</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let politica of _servicio.politicas">
                      <th scope="row">{{ politica.id }}</th>
                      <td>{{ politica.Horario }}</td>
                      <td>{{ politica.Detalle }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

            <p class="note">*En el punto 2, de no concretar el envío para el mismo día, la entrega pasará al día siguiente.</p>
            <p class="note">*En el punto 3, de no concretar el envío para el sábado, la entrega pasará al día lunes.</p>
          </div>
        </div>
      </div>

      <div class="col-12 mt-4">
        <div class="news-section">
          <h2 class="news-title text-center">{{_servicio.info.politica4}}</h2>
          <div class="news-content">
            <p>
              {{_servicio.info.cuerpo4}}
            </p>
          </div>
        </div>
      </div>

      <div class="col-12 mt-4">
        <div class="news-section">
          <h2 class="news-title text-center">{{_servicio.info.politica5}}</h2>
          <div class="news-content">
            <p class="text-center">
              {{_servicio.info.cuerpo5}}
            </p>

            <div class="contact-icons text-center">
              <a href="https://tinyurl.com/distribucionBongusto" target="_blank" class="whatsapp-button">
                <i class="fab fa-whatsapp fa-lg"></i>
              </a>
              <a href="tel:+59168049254" target="_blank" class="phone-button">
                <i class="fs-2 material-icons">dialer_sip</i>
              </a>
            </div>
            <div class="contact-details text-center">
              <p class="contact-info">{{_servicio.info.distribucion}}</p>
              <p class="contact-description">WhatsApp exclusivo para coordinar la distribución</p>
            </div>



          </div>
        </div>
      </div>

    </div>
  </div>