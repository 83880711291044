import { Component } from '@angular/core';

@Component({
  selector: 'app-informacion-basica',
  templateUrl: './informacion-basica.component.html',
  styleUrls: ['./informacion-basica.component.css']
})

// informacion-basica.component.ts
// informacion-basica.component.ts
export class InformacionBasicaComponent {
  
  abrirGoogleMaps() {
    // La URL debería llevar al usuario a la misma vista que el iframe pero en el sitio de Google Maps
    const url = "https://maps.app.goo.gl/9Q6KCZfz1aCXuU1L9";
    window.open(url, '_blank');
  }
}
