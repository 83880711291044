<div class="title-container">
  <h1 class="title">Explora los sabores de Bon Gusto</h1>
  <p class="description">
    Vive una experiencia única con nuestra exquisita variedad de productos que destacan por su calidad y sabor inigualable.
  </p>
  <div class="button-container">
    <button mat-button class="cta-button" (click)="explorarProductos()">
      Ver productos
    </button>
  </div>
</div>
