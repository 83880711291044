// pdv-sur.module.ts
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccesibilidadMedidasSeguridadComponent } from './components/accesibilidad-medidas-seguridad/accesibilidad-medidas-seguridad.component';
import { CatalogoProductosComponent } from './components/catalogo-productos/catalogo-productos.component';
import { ContactoComoLlegarComponent } from './components/contacto-como-llegar/contacto-como-llegar.component';
import { DireccionsurComponent } from './components/direccionsur/direccionsur.component';
import { GaleriaImagenesComponent } from './components/galeria-imagenes/galeria-imagenes.component';
import { HorariosComponent } from './components/horarios/horarios.component';
import { InformacionBasicaComponent } from './components/informacion-basica/informacion-basica.component';
import { OpinionesTestimoniosComponent } from './components/opiniones-testimonios/opiniones-testimonios.component';
import { PreguntasFrecuentesComponent } from './components/preguntas-frecuentes/preguntas-frecuentes.component';
import { PromocionesNovedadesComponent } from './components/promociones-novedades/promociones-novedades.component';
import { RedesSocialesComponent } from './components/redes-sociales/redes-sociales.component';
import { ServiciosEspecialesComponent } from './components/servicios-especiales/servicios-especiales.component';
import { TituloComponent } from './components/titulo/titulo.component';
import { MaterialModule } from 'src/app/material/material.module';

// Importa los componentes individualmente

// ... importa el resto de tus componentes aquí



@NgModule({
  declarations: [
    AccesibilidadMedidasSeguridadComponent,
    CatalogoProductosComponent,
    ContactoComoLlegarComponent,
    DireccionsurComponent,
    GaleriaImagenesComponent,
    HorariosComponent,
    InformacionBasicaComponent,
    OpinionesTestimoniosComponent,
    PreguntasFrecuentesComponent,
    PromocionesNovedadesComponent,
    RedesSocialesComponent,
    ServiciosEspecialesComponent,
    TituloComponent
  ],
  imports: [
    CommonModule,
    MaterialModule
    // ... otros módulos que necesites
  ],
  exports: [
    AccesibilidadMedidasSeguridadComponent,
    CatalogoProductosComponent,
    ContactoComoLlegarComponent,
    DireccionsurComponent,
    GaleriaImagenesComponent,
    HorariosComponent,
    InformacionBasicaComponent,
    OpinionesTestimoniosComponent,
    PreguntasFrecuentesComponent,
    PromocionesNovedadesComponent,
    RedesSocialesComponent,
    ServiciosEspecialesComponent,
    TituloComponent
  ]
})
export class PdvSurModule { }
