// Módulos de Angular Core
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http'; // Importante para servicios que hacen llamadas HTTP

// Módulos de enrutamiento
import { AppRoutingModule } from './app-routing.module';

// Módulos de UI o diseño (Angular Material, FlexLayout)
import { MaterialModule } from './material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';

// Módulos compartidos o de funcionalidad
import { ShareModule } from './share/share.module';



// Servicios
import { InfoPaginaService } from './services/info-pagina.service';

// Componentes de páginas
import { AppComponent } from './app.component';
import { InicioComponent } from './pages/inicio/inicio.component';
import { NosotrosComponent } from './pages/nosotros/nosotros.component';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { EventosComponent } from './pages/eventos/eventos.component';
import { PoliticasComponent } from './pages/politicas/politicas.component';
import { VideosComponent } from './share/videos/videos.component';

// Pipes
import { YoutubePipe } from './pages/pipes/youtube.pipe';
import { PdvSurModule } from './mapas/pages/pdv-sur/pdv-sur.module';


// Import shared components via barrel file (if you have one)
// import * as fromShare from './share';

@NgModule({
  declarations: [

    AppComponent,
    ContactoComponent,
    EventosComponent,
    InicioComponent,
    NosotrosComponent,
    PoliticasComponent,
    VideosComponent,
    YoutubePipe,
    // Spread all components from the barrel
    // ...fromShare.components,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ShareModule,
    PdvSurModule

    
  ],
  providers: [InfoPaginaService],

  bootstrap: [AppComponent],
})
export class AppModule {}
