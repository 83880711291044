<div class="container nosotros">

  <app-historia></app-historia>

  <app-mision></app-mision>

  <app-vision></app-vision>

  <app-valores></app-valores>

</div>