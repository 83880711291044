<div class="container text-center py-4">
  <p class="mb-4">
    ¡Estamos aquí para ayudarte! Contáctanos a través de WhatsApp para obtener información rápida y directa (haz clic en el ícono de WhatsApp).
  </p>

  <!-- Carrusel de contactos -->
  <div class="carousel-container">
    <!-- Botón para retroceder -->
    <button class="carousel-control-prev" (click)="scrollLeft()">
      <span class="carousel-control-prev-icon">&#8249;</span>
    </button>

    <!-- Contenedor del carrusel, agregamos eventos táctiles -->
    <div class="carousel-scroll" #carouselScroll (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)" (touchend)="onTouchEnd()">
      <div class="carousel-iitem" *ngFor="let contacto of contactos">
        <div class="contact-card p-1 m-1">
          <a [href]="'https://api.whatsapp.com/send?phone=+591' + contacto.celular + '&text=¡Hola! ' + contacto.titular + ', deseo mayor información. Vengo del sitio web.'"
             target="_blank" class="whatsapp-button mb-3">
            <i class="fab fa-whatsapp fa-3x"></i>
          </a>
          <div>
            <p class="h6 m-0" style="color: #311A10;">
              <strong>{{ contacto.cargo }} </strong>
            </p>
            <h5 class="m-1">
              {{ contacto.ciudad }}
            </h5>
            <a [href]="'https://api.whatsapp.com/send?phone=+591' + contacto.celular + '&text=¡Hola! ' + contacto.titular + '! Me gustaría recibir más información.'"
               target="_blank" class="btn-whatsapp-custom">
              <strong>Cel.: {{ contacto.celular }}</strong>
            </a>
            <h5>{{ contacto.titular }}</h5>
          </div>
        </div>
      </div>
    </div>

    <!-- Botón para avanzar -->
    <button class="carousel-control-next" (click)="scrollRight()">
      <span class="carousel-control-next-icon">&#8250;</span>
    </button>
  </div>
</div>
