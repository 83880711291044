import { Component, Input, OnInit } from '@angular/core';
import { InfoPaginaService } from 'src/app/services/info-pagina.service';

@Component({
  selector: 'app-redes',
  templateUrl: './redes.component.html',
  styleUrls: ['./redes.component.css']
})
export class RedesComponent implements OnInit {

  @Input() iconColor: string = 'white'; // Color por defecto para los iconos
  @Input() hoverColor: string = '#311A10'; // Color en hover por defecto
  @Input() titleColor: string = 'black'; // Color por defecto para el título

  constructor( public _servicio: InfoPaginaService ) { }
  
  ngOnInit() {
    // Cargar redes sociales
  }
}
