import { Component } from '@angular/core';

@Component({
  selector: 'app-servicios-especiales',
  templateUrl: './servicios-especiales.component.html',
  styleUrls: ['./servicios-especiales.component.css']
})
export class ServiciosEspecialesComponent {

}
