<div class="horarios-container mb-3">
  <h1 class="text-center mb-4">Horarios de Atención</h1>
  <div class="horario">
    <h2 class="text-center mb-3">Lunes a jueves:</h2>
    <p class="text-center hrs">9:00 a 19:00 horas</p>
  </div>
  <div class="horario">
    <h2 class="text-center mb-3">Viernes:</h2>
    <p class="text-center hrs">9:00 a 20:00 horas</p>
  </div>
  <div class="horario">
    <h2 class="text-center mb-3">Sábado:</h2>
    <p class="text-center">9:00 a 14:00 horas</p>
  </div>
</div>
