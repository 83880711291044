import { Component } from '@angular/core';

@Component({
  selector: 'app-valores',
  templateUrl: './valores.component.html',
  styleUrls: ['./valores.component.css']
})
export class ValoresComponent {

  valores = [
    { nombre: 'Bienestar', icon: 'spa' },
    { nombre: 'Compromiso', icon: 'favorite' },
    { nombre: 'Diversidad e Inclusión', icon: 'public' },
    { nombre: 'Ética', icon: 'thumb_up' },
    { nombre: 'Excelencia', icon: 'star_rate' },
    { nombre: 'Honestidad', icon: 'verified_user' },
    { nombre: 'Innovación', icon: 'lightbulb' },
    { nombre: 'Integridad', icon: 'shield' },
    { nombre: 'Mejora continua', icon: 'trending_up' },
    { nombre: 'Respeto', icon: 'gavel' },
    { nombre: 'Sostenibilidad', icon: 'eco' },
    { nombre: 'Trabajo en equipo', icon: 'group' },
  ];
}
