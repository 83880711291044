import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Videos } from 'src/app/intefaces/info-video.interface'; 
import { InfoContactoService } from 'src/app/services/info-contacto.service';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent implements OnInit {

  videos: Videos[] = []; // Array para almacenar los videos

  // Referencia al contenedor de deslizamiento del carrusel
  @ViewChild('videoCarouselScroll', { static: true }) videoCarouselScroll!: ElementRef;

  constructor(public videosServices: InfoContactoService) { }

  ngOnInit(): void {
    // Cargar los videos desde el servicio
    this.videosServices.getVideos().subscribe(videos => {
      this.videos = videos;
    });
  }

  // Método para desplazar hacia la izquierda
  scrollLeft() {
    this.videoCarouselScroll.nativeElement.scrollBy({ left: -300, behavior: 'smooth' });
  }

  // Método para desplazar hacia la derecha
  scrollRight() {
    this.videoCarouselScroll.nativeElement.scrollBy({ left: 300, behavior: 'smooth' });
  }
}
