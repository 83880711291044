import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wp-button',
  templateUrl: './wp-button.component.html',
  styleUrls: ['./wp-button.component.css']
})
export class WpButtonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
