<div id="conceptos" class="carousel slide carousel-fade pb-2" data-bs-ride="carousel">

    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="" class="d-block w-100" alt="...">
        <div class="carousel-caption d-md-block">
          <p>Carne selecionada</p>
        </div>
      </div>
      <div class="carousel-item">
        <img src="" class="d-block w-100" alt="...">
        <div class="carousel-caption d-md-block">
          <p>sabor unico</p>
        </div>
      </div>
      <div class="carousel-item">
        <img src="" class="d-block w-100" alt="...">
        <div class="carousel-caption d-md-block">
          <p>calidad precio</p>
        </div>
      </div>
    </div>
  </div>