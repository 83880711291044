<!-- vision.component.html -->
<div class="container my-5 min-vh-50">
  <div class="row justify-content-center">
    <div class="col-md-10 col-lg-8">
      <div class="shadow p-4 vision-card">
        <h2 class="text-center vision-title">Visión</h2>
        <blockquote class="blockquote">
          <p class="mb-0">
            <i class="fas fa-quote-left quote-icon"></i>
            {{ visionTexto }}
            <i class="fas fa-quote-right quote-icon"></i>
          </p>
        </blockquote>
      </div>
    </div>
  </div>
</div>
